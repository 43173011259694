import client from './client';

const endpoint = 'ticket/';

const getTickets = async (eventName, id) => {
  const response = await client.get(endpoint + eventName + '/' + id);
  return response;
};

const createTicket = async (ticketName, description, limitedQuantity, ticketNumber, price, eventName) => {
  const response = await client.post(endpoint, {
    ticketName,
    eventName,
    description,
    limitedQuantity,
    ticketNumber,
    price
  });
  return response;
};

const updateEvent = (body) => {
  let eventName;
  let oldTicketName;
  const response = client.put(endpoint + eventName + '/' + oldTicketName, body);
  return response;
};

const deleteEvent = () => {
  let eventName;
  let ticketName;
  const response = client.get(endpoint + eventName + '/' + ticketName);
  return response;
};

const buyTicket = async (id, email, ticketHash) => {
  const response = await client.post(endpoint + 'buy/' + id, { email: email, ticketReference: ticketHash});
  return response;
};

const uploadImage = async (data) => {
  const response = await client.post(`${endpoint}upload/`, data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  return response;
};

const getImage = async (imageFile) => {
  const response = await client.get(`image/${imageFile}`);
  return response;
};

const scanTicket = async (id, coordinator) => {
  const response = await client.patch(`${endpoint}scan/${id}`, coordinator);
  return response;
};

const getTicketsByUser = async () => {
  const response = await client.get(`${endpoint}getUserTickets`);
  return response;
};

const getTicketsTypes = async (eventId) => {
  const response = await client.get(`${endpoint}getEventTickets?eventId=${eventId}`);
  return response;
};

const editTicket = async (ticket, ticketId) => {
  const response = await client.patch(`${endpoint}editTicket/${ticketId}`, ticket)
}

const getOverallTicketsDetails = async () => {
  
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getTickets,
  createTicket,
  updateEvent,
  deleteEvent,
  buyTicket,
  uploadImage,
  getImage,
  scanTicket,
  getTicketsByUser,
  getTicketsTypes,
  editTicket
};
